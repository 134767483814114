import { createApp } from "vue";
import App from "./App.vue";
import store from "@/store";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  { path: "/", component: () => import("./pages/Landing2.vue") },
  // { path: '/my', component: MyCollections },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const app = createApp(App);
app.use(store);
app.use(router);
store.dispatch("user/init").then(() => app.mount("#app"));
