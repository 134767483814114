const getDefaultState = () => ({ authorizedAddress: null });
const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ACTIVE_ADDRESS: (state, address) => {
    state.authorizedAddress = address;
  },
};

const actions = {
  async init({ commit }) {
    commit("RESET_STATE");
  },
};

const getters = {
  authorizedAddress: (state) => {
    return state.authorizedAddress;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
